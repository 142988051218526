$(function () {
    "use strict";
    var Engine = {
        plugins: {
            // Nav Selected State
            navSelectedState: function () {
                $(".site-nav__main > ul > li > a").each(function () {
                    var $this = $(this);
                    if ($this.attr('href') === window.location.pathname + window.location.search || $this.attr('href') === window.location.href) {
                        $this.addClass('selected').parents('li').addClass('selected');
                    }
                });
            },
            // Mobile Nav Toggle
            mobileNav: function() {
                $('.nav-toggle').on('click', function() {
                    var $this = $('body');
                    if ($this.hasClass('nav--closed')) {
                        $this.removeClass('nav--closed').addClass('nav--open')
                    }   else if ($this.hasClass('nav--open')) {
                        $this.removeClass('nav--open').addClass('nav--closed');
                    }   else {
                        $this.addClass('nav--open');
                    }
                });
            },
            // Initial image load
            initialImages: function() {
                $('body').imagesLoaded().always( function( instance ) {
                    $('.site-logo img, .hero__image img').addClass('has-loaded');
                });
            },
            // foundation
            foundation: function() {
                $(document).foundation();
            },
            // matchHeight
            cardHeight: function() {
                $(document).ready(function() {
                    $('.card-grid .card-grid__item').matchHeight();
                });
            }
        },
        global: {
            initialLoad: function() {
                $( document ).ready(function() {
                    var $this = $('html');
                    if ($this.hasClass('is-animating')) {
                        $this.removeClass('is-animating')
                    }
                });
            }, // end initialLoad
            pageTrans: function() {
                var options = {
                    LINK_SELECTOR: 'a[href^="' + window.location.origin + '"]:not([data-no-swup]), a[href^="/"]:not([data-no-swup]), a[href^="#"]:not([data-no-swup])',
                    elements: ['#view'],
                    animationSelector: '[class*="trans--"]',
                    cache: true,
                    preload: false,
                    scroll: true,
                    animateScroll: false,
                    plugins: [
                        swupGtmPlugin
                    ]
                };
                const view = new Swup(options);
                document.addEventListener('swup:contentReplaced', event => {
                    Engine.plugins.initialImages();
                    Engine.plugins.foundation();
                    Engine.plugins.navSelectedState();
                    Engine.plugins.mobileNav();
                    Engine.plugins.cardHeight();
                });
            } // end pageTrans
        } // end global
    };
    Engine.global.initialLoad();
    Engine.global.pageTrans();
    Engine.plugins.initialImages();
    Engine.plugins.foundation();
    Engine.plugins.navSelectedState();
    Engine.plugins.mobileNav();
    Engine.plugins.cardHeight();
});